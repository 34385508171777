import { Document } from '@contentful/rich-text-types';
import { GetServerSidePropsContext } from 'next';
import {
  TypeGenericPageTemplate,
  TypeGenericPageTemplateSkeleton,
  TypeLink,
} from '../../@types/generated';
import MainHero from '../common-components/component-library/heroes/MainHero';
import CTASectionLayout from '../common-components/component-library/layout/CTASectionLayout';
import FormCTA from '../common-components/component-library/layout/FormCTA';
import HighlightLayout from '../common-components/component-library/layout/HighlightLayout';
import MapMetricsLayout from '../common-components/component-library/layout/MapMetricsLayout';
import PartnerLogosLayout from '../common-components/component-library/layout/PartnerLogosLayout';
import HighlightedProfilesLayout from '../common-components/component-library/layout/highlightedProfilesLayout/HighlightedProfilesLayout';
import { transformMetaHeaderType } from '../common-components/contentful-elements/MetaHeader';
import {
  createTemplatePage,
  getLandingPageServerSideProps,
} from '../common-components/contentful-elements/pages/template';
import { TemplatePageProps } from '../common-components/contentful-elements/util/template';
import { getEntries } from '../utils/api/contentful';
import config from '../utils/config';
import { UrlError } from '../utils/error';
import { LOCALES } from '../utils/locale';
import withAppProps from '../utils/withAppProps';

const TopLevelPage = createTemplatePage({ campaignName: 'top-level-page' });

const getOldConfiguration = (ctx: GetServerSidePropsContext) => {
  const { query, locale: language, defaultLocale } = ctx;
  const name: string = query.name as string;
  const locale = LOCALES[language ?? defaultLocale ?? 'en'];
  return {
    slug: name,
    tags: ['Top level'],
    locale,
  };
};

type Highlight = Partial<{
  sectionTitle: string;
  text: Document;
  buttons: Array<TypeLink | undefined>;
  image: Partial<{
    imageUrl: string;
    imageText: string;
  }>;
}>;
export type GenericPageProps = {
  isGenericPage: boolean;
  title?: string;
  hero: Partial<{
    title: string;
    paragraph: string;
    image: string;
    buttons: Array<
      Partial<{
        text: string;
        url: string;
      }>
    >;
  }>;
  highlight1: Highlight;
  highlight2: Highlight;
  highlight3: Highlight;
  metrics: Partial<{
    title: string;
    values: Array<
      Partial<{
        metricName: string;
        metricValue: string;
      }>
    >;
  }>;
  experts: Partial<{
    sectionTitle: string;
    title: string;
    text: string;
    mainExpertsTitle: string;
    mainExperts: Array<
      Partial<{
        name: string;
        title: string;
        imageUrl: string;
        description: string;
      }>
    >;
    secondaryExpertsTitle: string;
    secondaryExperts: Array<
      Partial<{
        name: string;
        title: string;
        imageUrl: string;
      }>
    >;
  }>;
  callToAction: Partial<{
    sectionTitle: string;
    text: string;
    buttons: Array<TypeLink | undefined>;
    backgroundImageUrl: string;
  }>;

  highlight4: Highlight;
  highlight5: Highlight;
  partnerLogos: Partial<{
    title: string;
    logosList: Array<
      Partial<{
        link: string;
        image: {
          url: string;
          name?: string;
        };
      }>
    >;
  }>;
  contactUs: Partial<{
    sectionTitle: string;
    title: string;
    sections: Array<
      Partial<{
        title: string;
        description: Document;
        link: Partial<{
          title: string;
          url: string;
        }>;
      }>
    >;
  }>;
};

export const GenericPageTemplate = function (props: GenericPageProps) {
  const {
    hero,
    highlight1,
    highlight2,
    highlight3,
    highlight4,
    highlight5,
    metrics,
    experts,
    callToAction,
    partnerLogos,
    contactUs,
  } = props;

  function Highlight({
    sectionTitle,
    text,
    buttons,
    image,
    rightAlignedCopy,
  }: Highlight & { rightAlignedCopy?: boolean }) {
    return (
      <HighlightLayout
        rightAlignedCopy={rightAlignedCopy}
        sectionTitle={sectionTitle}
        text={text}
        buttons={buttons?.filter((button): button is TypeLink => !!button)}
        buttonVariant="text"
        images={image ? [image] : undefined}
      />
    );
  }

  return (
    <>
      <MainHero
        sectionTitle={hero.title}
        paragraph={hero.paragraph}
        imageUrl={hero.image}
        primaryButtonLabel={hero.buttons?.[0]?.text}
        primaryButtonHref={hero.buttons?.[0]?.url}
        secondaryButtonLabel={hero.buttons?.[1]?.text}
        secondaryButtonHref={hero.buttons?.[1]?.url}
      />
      <Highlight {...highlight1} />
      <Highlight {...highlight2} rightAlignedCopy />
      <Highlight {...highlight3} />

      <MapMetricsLayout sectionTitle={metrics.title} fundMetrics={metrics.values} />

      <HighlightedProfilesLayout
        sectionTitle={experts.sectionTitle}
        title={experts.title}
        paragraph={experts.text}
        primaryProfilesHeadline={experts.mainExpertsTitle}
        primaryProfiles={experts.mainExperts}
        secondaryProfilesHeadline={experts.secondaryExpertsTitle}
        secondaryProfiles={experts.secondaryExperts}
      />

      <CTASectionLayout
        sectionTitle={callToAction.sectionTitle}
        title={callToAction.text}
        buttons={callToAction.buttons?.filter((button): button is TypeLink => !!button)}
        backgroundImageUrl={callToAction.backgroundImageUrl}
      />
      <Highlight {...highlight4} />
      <Highlight {...highlight5} rightAlignedCopy />
      <PartnerLogosLayout title={partnerLogos.title} logosList={partnerLogos.logosList} />
      <FormCTA
        ctaFormSectionTitle={contactUs.sectionTitle}
        ctaFormTitle={contactUs.title}
        ctaFormSections={contactUs.sections}
      />
    </>
  );
};

export async function getGenericPageProps(page: TypeGenericPageTemplate) {
  const { metaHeader, ...fields } = page.fields;

  function createHighlightImage(image?: TypeGenericPageTemplate['fields']['highlight1Image']) {
    if (!image) return;
    return {
      imageUrl:
        image.fields?.imageLandscape?.fields.file?.url ||
        image.fields.imagePortrait?.fields.file?.url,
      imageText: image?.fields.credit,
    };
  }

  return {
    props: {
      isGenericPage: true,
      title: fields.heroSectionTitle,
      hero: {
        title: fields.heroSectionTitle,
        paragraph: fields.heroParagraph,
        image: fields.heroImage?.fields.imageLandscape?.fields.file?.url,
        buttons: fields.heroCTAs?.map((cta) => ({
          text: cta?.fields.identifier,
          url: cta?.fields.externalUrl,
        })),
      },
      highlight1: {
        sectionTitle: fields.highlight1SectionTitle,
        text: fields.highlight1Text,
        buttons: fields.highlight1Buttons,
        image: createHighlightImage(fields.highlight1Image),
      },
      highlight2: {
        sectionTitle: fields.highlight2SectionTitle,
        text: fields.highlight2Text,
        buttons: fields.highlight2Buttons,
        image: createHighlightImage(fields.highlight2Image),
      },
      highlight3: {
        sectionTitle: fields.highlight3SectionTitle,
        text: fields.highlight3Text,
        buttons: fields.highlight3Buttons,
        image: createHighlightImage(fields.highlight3Image),
      },
      highlight4: {
        sectionTitle: fields.highlight4SectionTitle,
        text: fields.highlight4Text,
        buttons: fields.highlight4Buttons,
        image: createHighlightImage(fields.highlight4Image),
      },
      highlight5: {
        sectionTitle: fields.highlight5SectionTitle,
        text: fields.highlight5Text,
        buttons: fields.highlight5Buttons,
        image: createHighlightImage(fields.highlight5Image),
      },
      metrics: {
        title: fields.metricsSectionTitle,
        values: fields.metricsList?.map((metric) => ({
          metricName: metric?.fields.title,
          metricValue: metric?.fields.metricValue,
          description: metric?.fields.description,
        })),
      },
      experts: {
        sectionTitle: fields.expertsSectionTitle,
        title: fields.expertsTitle,
        text: fields.expertsText,
        mainExpertsTitle: fields.expertsProfileTitle,
        mainExperts: fields.expertsProfileList?.map((expert) => ({
          name: expert?.fields.name,
          title: expert?.fields.title,
          imageUrl: expert?.fields.cutoutImage?.fields.file?.url,
          description: expert?.fields.description,
        })),
        secondaryExpertsTitle: fields.expertsSecondaryProfileTitle,
        secondaryExperts: fields.expertsSecondaryProfileList?.map((expert) => ({
          name: expert?.fields.name,
          title: expert?.fields.title,
          imageUrl: expert?.fields.cutoutImage?.fields.file?.url,
        })),
      },

      callToAction: {
        sectionTitle: fields.callToActionSectionTitle,
        text: fields.callToActionText,
        buttons: fields.callToActionButtons,
        backgroundImageUrl: fields.callToActionBackgroundImage?.fields.file?.url,
      },
      partnerLogos: {
        title: fields.partnerLogosTitle,
        logosList: fields.partnerLogosImageLinks?.map((partnerLogo) => ({
          link: partnerLogo?.fields?.link,
          image: {
            url: partnerLogo?.fields?.image?.fields.file?.url ?? '',
            name: partnerLogo?.fields?.image?.fields.file?.fileName,
          },
        })),
      },
      contactUs: {
        sectionTitle: fields.contactUsSectionTitle,
        title: fields.contactUsTitle,
        sections: fields.contactUsSections?.map((section) => ({
          title: section?.fields.title,
          description: section?.fields.description,
          link: {
            title: section?.fields.link?.[0]?.fields.identifier,
            url: section?.fields.link?.[0]?.fields.externalUrl,
          },
        })),
      },
    },
    pageAppProps: {
      metaHeader: transformMetaHeaderType({
        metaHeader,
        overrides: {
          canonicalLink: `${config.HOST_URL}/${fields.slug}`,
        },
      }),
    },
  };
}

export const getServerSideProps = withAppProps<TemplatePageProps | GenericPageProps>(
  async (context: GetServerSidePropsContext) => {
    try {
      const { slug, tags, locale } = getOldConfiguration(context);
      return await getLandingPageServerSideProps({
        slug,
        tags,
        locale,
        context,
        skipErrorLogging: true,
      });
    } catch (e) {
      const { name } = context.query;

      if (!name || Array.isArray(name)) throw new Error('No name provided');

      const {
        items: [page],
      } = await getEntries<TypeGenericPageTemplateSkeleton>({
        content_type: 'genericPageTemplate',
        'fields.slug': name,
        include: 10,
      });

      if (!page) {
        throw new UrlError(`No page found for slug ${name}`, { status: 404 });
      }

      return await getGenericPageProps(page);
    }
  },
);

export function isGenericPageProps(
  props: TemplatePageProps | GenericPageProps,
): props is GenericPageProps {
  return 'isGenericPage' in props && props?.isGenericPage === true;
}

export default function Page(props: TemplatePageProps | GenericPageProps) {
  if (isGenericPageProps(props)) {
    return <GenericPageTemplate {...props} />;
  }
  return <TopLevelPage {...props} />;
}
