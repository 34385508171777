import styled from '@emotion/styled';
import { breakpoints, colors, spacing } from '../../../utils/styleguide';
import Divider from '../Divider';
import SectionTitle from '../SectionTitle';
import Typography from '../text/Typography';
import SquareMap from '../squareMap/SquareMap';
import Container from './Container';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[5]}px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  gap: ${spacing[6]}px;

  ${breakpoints.desktop} {
    padding-left: ${spacing[5]}px;
  }
`;
const MapWrapper = styled.div`
  height: 100%;
`;

const MetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[6]}px;

  ${breakpoints.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Metric = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
`;

type Props = {
  sectionTitle?: string;
  mapItems?: {
    label?: string;
    locationCodes: string[];
    disabled?: boolean;
  }[];
  fundMetrics?: {
    metricValue?: string;
    metricName?: string;
    description?: string;
  }[];
  disableVerticalPadding?: boolean;
};

export default function MapMetricsLayout({
  sectionTitle,
  mapItems,
  fundMetrics,
  disableVerticalPadding,
}: Props) {
  if (!sectionTitle && !fundMetrics?.length && !mapItems?.length) return null;
  const hasMapLocations = !!mapItems?.flatMap(({ locationCodes }) => locationCodes).length;

  return (
    <Container disableVerticalPadding={disableVerticalPadding}>
      <Wrapper>
        {sectionTitle && <SectionTitle title={sectionTitle} />}
        <ContentWrapper>
          {hasMapLocations ? (
            <MapWrapper>
              <SquareMap items={mapItems} />
            </MapWrapper>
          ) : null}
          {!!fundMetrics?.length && (
            <MetricsWrapper>
              {fundMetrics.map((fundMetric, index) => (
                <Metric key={index}>
                  <Typography variant="h1">{fundMetric.metricValue}</Typography>
                  <Typography variant="overlineBold">{fundMetric.metricName}</Typography>
                  <Divider color={colors.black} />
                  <Typography variant="body">{fundMetric.description}</Typography>
                </Metric>
              ))}
            </MetricsWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
}
